@import '../../UserVar/uservar';

/* Subscribe */

.subscribe-container {
  grid-column: 2/4;
  display: grid;
  grid-template-rows: 8rem 6rem 1fr;
  align-items: center;
  .contant-meetzoo {
    position: absolute;
    color: $topBar;
    margin-left: 13%;
    margin-top: 50rem;
    cursor: pointer;
    .email-action {
      text-decoration: none;
      color: $topBar;
    }
  }
  .row {
    grid-row: 2/3;
    display: flex;
    border-top: solid;
    border-width: thin;
    border-color: $iconUnselect;
  }
  .settings {
    margin-left: 1rem;
    display: flex;
    align-items: center;
    color: $textPrimary;

    .title {
      color: $topBar;
    }

    p {
      font-size: 2.5rem;
      margin-right: 1rem;
    }
    .link {
      color: $textPrimary;
      text-decoration: none;
    }
    .back {
      margin-top: 1rem;
      font-size: 4rem;
      display: none;
    }

    .active-menu {
      color: $topBar;
    }
  }
  .subscription-container {
    align-self: baseline;
    grid-row: 3;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;

    .credit-card {
      grid-column: 1/-1;
      color: $textSecondary;
      justify-content: center;
      display: grid;
      width: 25rem;
      text-align: center;

      * {
        justify-self: center;
      }
      .card {
        width: 25rem;
        justify-self: center;
        .pay {
          margin-left: 2.2rem;
        }
        .card-element {
          font-size: 1.5rem;
          margin-top: 1rem;
          border: solid;
          border-width: thin;
          width: 25rem;
          padding: 1rem;
          border-radius: 0.5rem;
          margin-left: -1rem;
          .input {
            margin: 0px;
            padding: 0px;
            border: none;
            width: 25rem;
            height: 2rem;
            color: $textSecondary;
            font-weight: 500;
            font-size: 1.5rem;
          }
          input:focus {
            outline: none;
          }
        }
        .button-pay {
          margin-top: 1rem;
          margin-left: -1rem;

          .submit {
            width: 27.2rem;
            height: 4rem;
          }
        }

        button {
          border-radius: 0.5rem;
          color: $pure;
          border: solid;
          border-width: thin;
          background-color: $topBar;
          width: 15rem;
          height: 3rem;
          cursor: pointer;
          &:active {
            background-color: $pure;
            color: $topBar;
          }
        }
      }
      p {
        width: 20rem;
        justify-self: center;
      }
    }

    .team-member {
      padding-right: 8rem;
      padding-left: 8rem;
      border-left: solid;
      border-right: solid;
      border-width: thin;
      border-color: $iconUnselect;
    }
    .plan {
      display: flex;
      flex-direction: column;
      width: 18rem;
      align-items: center;
      text-align: center;
      h1 {
        display: flex;
        p {
          margin: 0.5rem;
        }
      }
      .text {
        height: 10rem;
      }
      h2 {
        font-size: 2rem;
      }
      .button {
        width: 15rem;
        padding: 1rem;
        background-color: $topBar;
        color: $pure;
        border: $topBar;
        border-radius: 0.2rem;
      }
      .button:active {
        background-color: $pure;
        color: $topBar;
      }
      .button-select {
        width: 15rem;
        padding: 1rem;
        color: $textPrimary;
        background-color: $pure;
        border-color: $iconUnselect;
        border-width: thin;
        border-radius: 0.2rem;
      }
      .button-select:active {
        background-color: $topBar;
        color: $pure;
      }
    }
    .profile {
      grid-column: 1/-1;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $textSecondary;
      p {
        margin: 2rem;
      }
      .input {
        width: 300px;
        margin-bottom: 2rem;
        background: $pure;
        color: $textSecondary;
        border-radius: 3px;
      }

      img {
        width: 20rem;
        height: 20rem;
        border-radius: 50%;
      }
      .icon {
        font-size: 2rem;
        margin-right: 1rem;
      }
      .button-select {
        color: $topBar;
        background-color: $pure;
        border-color: $topBar;
        border-width: thin;
        border-radius: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.7rem;
      }
      .button-select:active {
        background-color: $topBar;
        color: $pure;
      }
      .link {
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .subscribe-container {
    width: 100vw;
    grid-column: 1/-1;
    margin-top: -8rem;
    display: grid;
    .contant-meetzoo {
      text-align: center;
      margin-top: 110rem;
      padding-bottom: 2rem;
      margin-left: 0.5rem;
    }
    .row {
      border: none;
    }
    .title {
      color: $pure;
    }
    .settings {
      .back {
        display: grid;
        color: $pure;
      }
    }
  }
  .subscription-container {
    display: grid;
    grid-template-areas: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    justify-self: center;

    .free-plan {
      grid-column: 1/-1;
      grid-row: 1/2;
    }
    .team-member {
      margin-top: 1rem;
      padding-bottom: 1rem;
      border-top: solid;
      border-bottom: solid;
      border-left: none !important;
      border-right: none !important;
      grid-column: 1/-1;
      grid-row: 2/3;
    }
    .organizer {
      grid-column: 1/-1;
      grid-row: 3/4;
    }
    .button {
      margin-bottom: 2rem;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .subscribe-container {
    -ms-grid-row: 2 !important;
    -ms-grid-row-span: 2 !important;
    grid-row: 2/4 !important;
    -ms-grid-column: 2 !important;
    -ms-grid-column-span: 2 !important;
    grid-column: 2/4 !important;
    .subscription-container {
      display: -ms-grid;
      display: grid;
      -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
      -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
    }
    .credit-card {
      -ms-grid-column: 2 !important;
      -ms-grid-column-span: 1 !important;
      grid-column: 2/3 !important;
      padding: 20%;
      padding-left: 31% !important;
    }
    .free-plan {
      -ms-grid-column: 1 !important;
      -ms-grid-column-span: 1 !important;
      grid-column: 1/2 !important;
      padding: 20%;
      padding-left: 31% !important;
      .plan {
        .text {
          width: 20rem;
        }
      }
    }
    .team-member {
      -ms-grid-column: 2 !important;
      -ms-grid-column-span: 1 !important;
      grid-column: 2/3 !important;
      padding: 20%;
      padding-left: 31% !important;
      .plan {
        .text {
          width: 20rem;
        }
      }
    }
    .organizer {
      -ms-grid-column: 3 !important;
      -ms-grid-column-span: 1 !important;
      grid-column: 3/4 !important;
      padding: 20%;
      padding-left: 31% !important;
      .plan {
        .text {
          width: 20rem;
        }
      }
    }
    .profile {
      -ms-grid-column: 2 !important;
      -ms-grid-column-span: 1 !important;
      grid-column: 2/3 !important;
    }
  }
}
