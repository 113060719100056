@import '../../UserVar/uservar';

/* PERSON */

.person {
  grid-column: 2/4;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  color: $textPrimary;
  p {
    margin-left: 0.5rem;
  }
  .name {
    display: flex;
    align-items: center;
    img {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
    }
    img:before {
      width: 4rem;
      height: 4rem;
      background-image: url('../../assets/personavatar.png');
      border-radius: 50%;
    }
    .person-icon {
      font-size: 3rem;
    }
    .toogle-icon {
      color: $topBar;
      font-size: 4.8rem;
      margin-left: -0.4rem;
      margin-right: -0.4rem;
    }
  }
  .last-meeting {
    display: flex;
    .people-truncate {
      font-size: 1.4rem;
      width: 20rem;
    }
  }
}
.person-add {
  display: grid;
  align-items: center;
  font-size: 2rem;
  color: $iconUnselect;
  input::-ms-clear {
    display: none;
  }
  .user-add {
    display: flex;
    align-items: center;
  }

  p {
    margin-left: 0.5rem;
    color: $textPrimary;
  }
  .add-icon {
    margin-top: 0.8rem;
    font-size: 4rem;
    margin-right: 0.5rem;
  }
  .add-icon:hover {
    color: $topBar;
  }
  input {
    width: 30rem;
    border: none;
  }
}
.person-remove {
  margin-top: 1rem;
  grid-column: 1/3;
  display: flex;
  align-items: center;
  font-size: 2rem;
  color: $topBar;
  .button {
    display: flex;
    background: none;
    border: none;
    cursor: pointer;
  }
  .button:focus {
    color: $topBar;
  }
  p {
    margin: 0.5rem;
    color: $textPrimary;
  }
  .text {
    cursor: pointer;
  }
  .text:hover {
    color: $topBar;
  }
  .icon-cancel {
    color: $topBar;
    font-size: 3rem;
    cursor: pointer;
  }
  .icon {
    color: $topBar;
    font-size: 3rem;
    cursor: pointer;
  }
  .icon-mark {
    color: $iconCheck;
    font-size: 3rem;
    cursor: pointer;
  }
}
.persondetail-container {
  border: solid;
  margin-left: 1rem;
  .person-menu {
    display: none;
  }
  .person-title {
    margin-top: 1rem;
    display: grid;
    justify-items: center;
    img {
      width: 100px;
    }
    p {
      color: $textPrimary;
    }
  }
  .week-info {
    grid-column: 1/-1;
  }
}

@media only screen and (max-width: 800px) {
  .persondetail-container {
    width: 100vw;
    display: grid;
    justify-content: center;
    .person-title {
      grid-column: 1 /-1;
    }

    .week-info {
      grid-column: 1/-1;
    }
  }
  .person {
    .name {
      display: grid;
      grid-column: 1/-1;
      display: flex;
      p {
        font-size: 1.8rem;
      }
      .hide {
        display: none;
      }
    }
  }
  .person-remove {
    margin-bottom: 2rem;
    margin-left: 1rem;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .person {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
  }
  .person > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  .person > *:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }
  .person > *:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
  .person > *:nth-child(4) {
    -ms-grid-row: 1;
    -ms-grid-column: 4;
  }
  .person > *:nth-child(5) {
    -ms-grid-row: 1;
    -ms-grid-column: 5;
  }
  .person-remove {
    -ms-grid-row: 3 !important;
    -ms-grid-row-span: 1 !important;
    grid-row: 3/4 !important;
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 2 !important;
    grid-column: 1/3 !important;
    margin-top: -6rem;
  }
  .person-add {
    input::-ms-clear {
      display: none;
    }
  }
}
