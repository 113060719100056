@import '../../UserVar/uservar';

/* ACTIONS */

.actions {
  border-left: solid;
  border-color: $iconUnselect;
  border-width: thin;
  display: grid;
  grid-template-columns: 7.5rem 1fr 6rem;
  grid-auto-rows: 7rem 1fr;
  align-items: baseline;
  color: $topBar;

  .a1 {
    margin-left: 0.5rem;
    font-size: 2rem;
  }

  .a2 {
    margin-left: 0.5rem;
  }

  .actions-items {
    grid-column: 1/-1;
    align-self: baseline;
  }
}

.actions-item {
  display: grid;
  grid-template-columns: 5rem auto 8rem;
  grid-template-rows: 3rem;
  align-items: center;

  .check {
    margin-left: 1.5rem;
    font-size: 2rem;
    color: $iconUnselect;

    &:hover {
      color: $iconCheck;
    }
  }

  .ac1 {
    color: $textSecondary;
    p {
      font-size: 1.8rem;
    }
  }
  .ac2 {
    color: $textPrimary;
    p {
      font-size: 1rem;
    }
  }
}
.action-detail {
  .actions-container {
    .name {
      .mentions-box > * {
        textarea:first-line {
          font-weight: 600 !important;
        }
      }
    }
  }
}

.actions-container {
  margin-top: 1rem;
  grid-column: 1/4;
  display: grid;
  align-items: center;
  color: $textPrimary;
  .name {
    display: flex;
    align-items: baseline;
    .toogle-icon {
      font-size: 5.1rem;
      margin-left: -0.5rem;
      margin-top: -1.6rem;
      margin-right: 0.2rem;
      color: $topBar;
      transform: translateY(40%);
    }
    .person-icon {
      transform: translateY(40%);
    }

    .mentions__mention {
      color: 'white';
      z-index: 1;
    }

    .mentions-box {
      width: 95%;
      overflow-y: auto;
      word-wrap: break-word;
      overflow-wrap: break-word;
      white-space: pre-wrap;
      word-break: break-all;
      .mentions__mention {
        color: 'white';
        z-index: 1;
      }

      .metions::first-line {
        font-weight: 600 !important;
      }
      * {
        textarea::first-line {
          font-weight: 600 !important;
        }
        textarea:first-line {
          font-weight: 600 !important;
        }
      }
      textarea::first-line {
        font-weight: 600 !important;
        color: $textSecondary;
      }
      textarea:first-line {
        font-weight: 600 !important;
        color: $textSecondary !important;
      }
    }
    .add-icon {
      font-size: 4rem;
      transform: translateY(65%);
      &:hover {
        color: $topBar;
      }
    }
    .mentions-input {
      width: 10rem;
    }
    img {
      width: 4rem !important;
      height: 4rem !important;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
    .menu {
      transform: translateY(40%);
      margin-right: 10rem;
      margin-left: 1rem;

      display: flex;
      font-size: 4rem;
      align-items: center;
      .checked {
        color: $iconCheck;
        margin-right: 2rem;
      }
      .unchecked {
        color: $iconUnselect;
        margin-right: 2rem;
      }
      .shared {
        font-size: 2.3rem;
        padding: 0.5rem;
        color: $pure;
        background-color: $topBar;
        border-radius: 50%;
      }
      .unshared {
        color: $iconUnselect;
      }
    }
  }
  .person-remove {
    display: none;
    .button {
      align-items: center;
      .icon-cancel {
        font-size: 4rem;
      }
      .icon-mark {
        color: $iconCheck;
        font-size: 4rem !important;
      }
      p {
        font-size: 2rem;
        white-space: nowrap;
      }
    }
  }
}
@media (max-width: 1100px) {
  .actions {
    display: none;
  }
  .actions-container {
    .name {
      .toogle-icon {
        font-size: 5.4rem !important;
      }
      .menu {
        margin-right: 1rem;
      }
    }
  }
}

@media (max-width: 800px) {
  .actions {
    display: none;
  }
  .actions-container {
    .name {
      .toogle-icon {
        font-size: 6.2rem !important;
      }
      .menu {
        margin-right: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
}

@media (max-width: 500px) {
  .actions-container {
    .name {
      .toogle-icon {
        font-size: 7.8rem !important;
        margin-top: -3.5rem;
        margin-bottom: -0.2rem;
      }
      .menu {
        margin-left: -0.4rem;
        margin-right: -0.1rem;
      }
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .actions-container {
    .name {
      .mentions-box {
        .mentions {
          textarea {
            font-family: 'SF Display' !important;
          }
        }
      }
    }
  }
}

@media all and (min--moz-device-pixel-ratio: 0) {
  .layout-container {
    .home {
      .horizontal-line {
        margin-top: -122.8rem;
      }
    }
  }
}
