@import '../../UserVar/uservar';

.login {
  display: grid;
  grid-column: 1/-1;
  justify-content: center;
  align-content: center;
  background-image: url('../../assets/login_background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .transparent {
    opacity: 1;
  }

  .cover {
    position: absolute;
    width: 100vw;
    top: 46.5vh;
    z-index: -1;
    display: none;
    flex-direction: column;
  }
  .title {
    p {
      margin-left: 4rem;
      margin-top: -2rem;
      display: none;
    }
  }

  .img {
    height: 6rem;
    border: solid;
    color: $topBar;
  }

  .link {
    text-decoration: none;
  }

  .button {
    display: flex;
    background-color: $topBar;
    color: $pure;
    margin: 5px;
    text-decoration: none;
    cursor: pointer;
    .o365 {
      margin-left: 2rem;
    }

    p {
      margin: 1rem;
      display: flex;
      align-self: center;
      justify-content: center;
      white-space: nowrap;
      text-decoration: none;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .login {
    margin-top: 35vh;
    .title {
      position: absolute;
      top: 30vh;
      left: 50%;
      margin-left: -12rem;

      p {
        margin-top: 5rem;
      }
    }
    .cover {
      width: 100vw;
      top: 6vh;
      justify-items: center;
      .login {
        align-self: center;
        width: 28rem;
      }
    }
  }
}
