@import '../../UserVar/uservar';

.header-login {
  grid-column: 1/-1;
  background: $topBar;
  display: flex;
  align-items: center;
  color: $pure;

  .brand-login {
    display: flex;
    align-items: center;
    .brand-logo {
      margin-left: 1.4rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 14rem;
      height: auto;
    }
  }
}

.header {
  grid-column: 1/-1;
  background: $topBar;
  display: flex;
  align-items: center;
  color: $pure;
  p {
    font-size: 2rem;
  }

  .brand {
    display: flex;
    flex: 1 1 80%;
    align-items: center;

    img {
      margin-left: 1.4rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 14rem;
      height: auto;
    }
  }
  .logout {
    background: transparent;
    color: $pure;
    font-size: 2rem;
    border: none;
    cursor: pointer;
    &:hover {
      color: $textPrimary;
    }
  }
  .link {
    text-decoration: none;
    color: $pure;
  }
}

.nav {
  flex: 1 1 5%;
}
.header-menu {
  display: none;
}

.header-menu-list {
  display: none;
}

@media (max-width: 800px) {
  .header-login {
    justify-content: center;
  }
  .hide {
    display: none;
  }
  .logout {
    display: none;
  }
  .header-menu {
    display: block;
    font-size: 3rem;
  }
  .header {
    width: 100vw;
  }

  .header-menu-list {
    background: $topBar;
    display: flex;
    flex-direction: column;
    width: 100vw;
    position: absolute;
    color: $pure;
    top: 6.5rem;
    z-index: 2;

    p {
      margin-left: 0.5rem;
      border-bottom: solid;
      border-width: thin;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .header {
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-column: 1/4;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/2;
  }
}
