@font-face {
  font-family: 'SF Display';
  font-weight: 400;
  src: url('./assets/SF-UI-Display-Regular.woff');
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  font-family: SF Display, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
/* 1rem = 10px */
html {
  box-sizing: border-box;
  font-size: 52.5%;
}
p {
  font-size: 1.6rem;
}
h1 {
  font-size: 3rem;
}
code {
  font-family: SF Display, source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}
