$topBar: #00ADEE;
$mark: #b0d6f5;
$pure: #ffffff;
$iconUnselect: #d8d8d8;
$iconSelect: rgb(0, 39, 122);
$iconAlert: #d0021b;
$iconCheck: #7ed321;
$textPrimary: #9b9b9b;
$textSecondary: #62778c;
$textSecondaryNext: #c1c1c1;
$background: #ffffff;
$backgroundSecond: #f8f8f8;
$backgroundImage: url('../../assets/test_background.jpg');
