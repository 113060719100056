@import '../../UserVar/uservar';

/* MEETINGS */

.layout-container {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: 6.5rem 1fr;
  grid-template-columns: 8rem 1fr 28rem;
  background-image: $background;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .home {
    grid-column: 2/4;
    margin-left: 1rem;
    display: grid;
    grid-template-areas:
      'a b b b c'
      'd d d d d'
      'g g g g g';
    grid-template-rows: 5rem 4rem 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    .horizontal-line {
      grid-area: d;
      position: absolute;
      border-top: solid;
      border-width: thin;
      border-color: $iconUnselect;
      grid-column: 1/6;
      margin-top: 5rem;
      width: 100vw;
      margin-left: -1rem;
    }

    .main-control-actions {
      border: solid;
    }

    .meetings {
      margin-left: 0.5rem;
      grid-area: a;
      color: $topBar;
      margin-top: 1rem;
      p {
        font-size: 3rem;
      }
    }

    .search {
      grid-area: b;

      margin-left: 0.5rem;
      .input {
        font-size: 1.8rem;
        font-weight: 500;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border: 0px solid;
        background-color: $backgroundSecond;
        color: $textPrimary;
      }
      .input::placeholder {
        color: $textSecondaryNext;
      }
      input:focus {
        outline: none;
      }
    }
    .new {
      grid-area: c;
      margin-left: -15rem;
      p {
        margin: 0;
        text-decoration: none;
      }
      .link {
        text-decoration: none;
        color: $textSecondary;
      }
    }
    .show-completed {
      grid-area: c;
      display: flex;
      align-items: center;
      margin-left: -2rem;
      p {
        margin-left: 0.5rem;
        text-decoration: none;
        color: $topBar;
        margin-right: 0.5rem;
      }
    }
    .active-main-menu {
      color: $topBar;
      font-size: 2.5rem;
    }

    .none {
      font-size: 2.5rem;
    }

    .all {
      grid-area: d;
      margin-left: 0.5rem;
      font-size: 2.5rem;
      color: $textPrimary;
      cursor: pointer;
      &:hover {
        color: $topBar;
      }
      p {
        font-size: 2.5rem;
      }
    }
    .organised {
      grid-area: d;
      margin-left: 7rem;
      font-size: 2.5rem;
      color: $textPrimary;
      cursor: pointer;

      p {
        font-size: 2.5rem;
      }
    }
    .assigned {
      grid-area: d;
      margin-left: 27rem;
      font-size: 2.5rem;
      color: $textPrimary;
      cursor: pointer;

      p {
        font-size: 2.5rem;
      }
    }
    .attended {
      grid-area: d;
      margin-left: 28rem;
      font-size: 2.5rem;
      color: $textPrimary;
      cursor: pointer;
    }
    .week-container {
      grid-area: g;
      margin-left: 1rem;
      align-self: baseline;
      .link {
        text-decoration: none;
        color: $textSecondary;
      }
    }
    .add {
      display: none;
    }
  }

  /* WEEK */

  .w1 {
    grid-column: 1/-1;
    color: $topBar;
    font-size: 2rem;
    margin-bottom: 0rem;
    margin-left: -1rem;
    margin-top: 2.5rem;
  }

  .week {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: 10rem 1fr;
    grid-template-rows: 3.7rem;
    align-items: center;
    font-size: 1.5rem;
    text-decoration: none;

    .truncate {
      margin-left: 3rem;
      word-wrap: break-word;
      overflow-wrap: break-word;
      white-space: pre-wrap;
      word-break: break-all;
    }
    .not-truncate {
      display: none;
      margin-left: 2.2rem;
      border: solid;
    }

    .w1 {
      grid-column: 1/-1;
      color: $topBar;
      font-size: 2rem;
    }
    .w2 {
      margin-left: 0.3rem;
      margin-right: -10rem;
    }
    .w3 {
      color: $textSecondary;
      text-decoration: none;
    }
  }

  .week > .people-container {
    margin: 5px;
    grid-column: 1/-1;
    border: solid;
    display: grid;
    grid-template-columns: repeat(5 / 1fr);
  }
  .people-menu-a {
    grid-column: 1/2;
  }
}

@media only screen and (max-width: 800px) {
  .home {
    grid-column: 1/-1 !important;
    width: 100vw;
    margin-left: 0px !important;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 5rem 4rem 1fr 5rem;
    .meetings {
      grid-column: 1/-1;
      p {
        position: absolute;
        margin-top: -8rem;
        margin-left: 1rem;
        color: $pure;
      }
    }
    .horizontal-line {
      display: none;
    }
    .new-add-container {
      .show-completed {
        display: none;
      }
    }

    .search {
      grid-column: 1/-1;
      .input {
        width: 100%;
        font-size: 1.8rem !important;
        color: $textSecondary;
      }
      input:focus {
        color: $textSecondary;
      }
    }
    .week-container {
      grid-column: 1/-1;
      margin-top: -3rem;
    }
    .week {
      .w3 {
        margin-left: -4rem;
        font-size: 1.5rem;
      }
    }
  }

  .new {
    display: none;
  }
  .all {
    display: none;
  }
  .organised {
    display: none;
  }
  .attended {
    display: none;
  }
  .assigned {
    display: none;
  }
  .add {
    position: fixed !important;
    display: grid !important;
    bottom: 3rem;
  }
}
@media only screen and (max-width: 400px) {
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .layout-container {
    width: 100vw;
    height: 100vh;
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: 8rem 1fr;
    grid-template-rows: 8rem 1fr;
    -ms-grid-columns: 8rem 1fr 28rem;
    grid-template-columns: 8rem 1fr 28rem;
    background-image: $background;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .home {
      -ms-grid-row: 2 !important;
      -ms-grid-row-span: 2 !important;
      grid-row: 2/4 !important;
      -ms-grid-column: 2 !important;
      -ms-grid-column-span: 2 !important;
      grid-column: 2/4 !important;
      display: -ms-grid;
      display: grid;
      -ms-grid-rows: 5rem 4rem 1fr;
      grid-template-rows: 5rem 4rem 1fr;
      -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      .meetings {
        -ms-grid-column: 1 !important;
        -ms-grid-column-span: 1 !important;
        grid-column: 1/2 !important;
        -ms-grid-row: 1 !important;
        -ms-grid-row-span: 1 !important;
        grid-row: 1/2 !important;
        margin-top: -2rem;
      }
      .search {
        -ms-grid-column: 2 !important;
        -ms-grid-column-span: 3 !important;
        grid-column: 2/5 !important;
        -ms-grid-row: 1 !important;
        -ms-grid-row-span: 1 !important;
        grid-row: 1/2 !important;
        margin-top: 2rem;
      }
      .all {
        -ms-grid-column: 1 !important;
        -ms-grid-column-span: 1 !important;
        grid-column: 1/2 !important;
        -ms-grid-row: 2 !important;
        -ms-grid-row-span: 1 !important;
        grid-row: 2/3 !important;
        margin-top: -2rem;
      }
      .organised {
        -ms-grid-column: 1 !important;
        -ms-grid-column-span: 2 !important;
        grid-column: 1/3 !important;
        -ms-grid-row: 2 !important;
        -ms-grid-row-span: 1 !important;
        grid-row: 2/3 !important;
        margin-top: -2rem;
        margin-left: 6rem;
      }
      .week-container {
        -ms-grid-column: 1 !important;
        -ms-grid-column-span: 5 !important;
        grid-column: 1/6 !important;
        -ms-grid-row: 3 !important;
        -ms-grid-row-span: 1 !important;
        grid-row: 3/4 !important;
      }
      .new-add-container {
        -ms-grid-column: 4 !important;
        -ms-grid-column-span: 1 !important;
        grid-column: 4/6 !important;
        -ms-grid-row: 1 !important;
        -ms-grid-row-span: 1 !important;
        grid-row: 1/2 !important;
        margin-top: 2rem;
      }
    }
    .week {
      display: flex;
      margin-bottom: -2rem;
      .w2 {
        margin-right: 1rem;
      }
    }
  }
}

@media all and (min--moz-device-pixel-ratio: 0) {
  .layout-container {
    .home {
      .horizontal-line {
        display: none;
      }
    }
  }
}

@supports (-ms-ime-align: auto) {
  .layout-container {
    .home {
      .horizontal-line {
        display: none;
      }
    }
  }
}
