@import '../../UserVar/uservar';

/* MEETING */

.meeting {
  grid-column: 2/4;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 40rem 1fr;
  grid-template-rows: 5rem 10rem 3rem 1fr;
  align-items: center;
  margin-left: 1rem;

  .flex {
    display: flex;
    width: 160px;
    align-items: center;
  }

  .flex-date {
    display: flex;
    width: 230px;
    align-items: center;
  }

  .top {
    z-index: 1;
  }

  .calendar-picker {
    width: 1.8rem;
    transform: translateX(-14px);
    margin-left: -1.5rem;
    margin-top: -0.2rem;
  }

  .time-picker {
    margin-top: 0rem;
    width: 5.8rem;
    transform: translateX(-32px);
  }

  .page-detail {
    margin-top: -1rem;
    display: flex;
    grid-column: 1 /2;
    align-items: center;
    color: $topBar;
    font-size: 4rem;
    .mobile {
      display: none;
    }
    .back {
      color: $topBar;
      margin-top: 1rem;
    }
    p {
      font-size: 2.5rem;
    }
    .icon {
      font-size: 2.2rem;
    }
  }
  .date {
    grid-column: 2/3;
    display: grid;
    justify-content: left;
    align-items: center;
    color: $topBar;
    font-size: 2rem;

    .day {
      color: $textSecondary;
      font-size: 2rem;
      margin-bottom: 2.4rem;
    }

    p {
      margin-left: 0.5rem;
      color: $textSecondary;
    }
    .icon {
      margin-left: 2rem;
      font-size: 3rem;
      color: $iconUnselect;
    }
    .icon-select {
      color: $topBar;
      margin-left: 2rem;
      font-size: 3rem;
    }
  }
  .meeting-info {
    grid-column: 1/-1;
    color: $textSecondary;
    .mobile {
      display: none;
    }
  }
  .meeting-menu {
    margin-left: 0.5rem;
    grid-column: 1/-1;
    display: flex;
    color: $textPrimary;
    .link {
      text-decoration: none;
      color: $textPrimary;
      .active {
        color: $iconUnselect;
        border: none !important;
      }
      .basic {
        color: $topBar;
        border: none !important;
      }
    }

    p {
      font-size: 2.5rem;

      margin-right: 1.5rem;
    }
  }
}
.meeting-options-container {
  margin-left: 0.5rem;
  display: grid;
  grid-column: 1/-1;
  align-self: baseline;

  .action-detail {
    width: 90vw;
    grid-column: 1/-1;
  }
  .people-container {
    .people-menu {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      color: $textPrimary;
    }
  }
}

.add-meeting {
  grid-column: 2/4;
  display: grid;
  margin: 1rem;
  align-self: baseline;
  font-size: 1.5rem;
  .button {
    margin-top: 1rem;
    justify-self: center;
  }
  .back {
    display: none;
  }
}
@media only screen and (max-width: 800px) {
  .meeting {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 8rem 8rem 8rem;
    margin-left: 0rem;
    margin-right: 0rem;

    .flex-date {
      width: 160px;
    }

    .page-detail {
      color: $pure;
      position: absolute;
      top: 1rem;
      .web {
        display: none;
      }
      .mobile {
        display: grid;
      }
      .back {
        color: white;
        margin-right: -1.6rem;
      }
    }
    .date {
      grid-column: 1/-1;
      margin-top: -2rem;
      margin-left: 1rem;
      display: flex;
      .day {
        display: none;
      }
    }
    .meeting-info {
      margin-top: -2rem;
      margin-left: 1rem;
      .mobile {
        display: grid;
      }
      .web {
        display: none;
      }
    }
    .meeting-menu {
      margin-top: -3rem;
      width: 90vw;
      margin-left: 1rem;
    }
    .meeting-options-container {
      margin-left: 1rem;
      width: 90vw;
      margin-top: -2rem;
      p {
        font-size: 1rem;
      }
      .people-container {
        .people-menu {
          display: none;
        }
      }
    }
    width: 100vw;
  }

  .add-meeting {
    grid-column: 1/-1;
    width: 90vw;
    .back {
      display: grid !important;
      font-size: 4rem;
      position: absolute;
      margin-top: -7rem;
      color: $pure;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .meeting {
    -ms-grid-row: 2 !important;
    -ms-grid-row-span: 2 !important;
    grid-row: 2/4 !important;
    -ms-grid-column: 2 !important;
    -ms-grid-column-span: 2 !important;
    grid-column: 2/4 !important;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 40rem 40rem 1fr;
    grid-template-columns: 40rem 40rem 1fr;
    -ms-grid-rows: 5rem 10rem 3rem 1fr;
    grid-template-rows: 5rem 10rem 3rem 1fr;
    .page-detail {
      -ms-grid-row: 1 !important;
      -ms-grid-row-span: 1 !important;
      grid-row: 1/2 !important;
      -ms-grid-column: 1 !important;
      -ms-grid-column-span: 1 !important;
      grid-column: 1/2 !important;
    }
    .date {
      -ms-grid-row: 1 !important;
      -ms-grid-row-span: 1 !important;
      grid-row: 1/2 !important;
      -ms-grid-column: 2 !important;
      -ms-grid-column-span: 1 !important;
      grid-column: 2/3 !important;
    }
    .meeting-info {
      -ms-grid-row: 2 !important;
      -ms-grid-row-span: 1 !important;
      grid-row: 2/3 !important;
      -ms-grid-column: 1 !important;
      -ms-grid-column-span: 2 !important;
      grid-column: 1/3 !important;
    }
    .meeting-menu {
      -ms-grid-row: 3 !important;
      -ms-grid-row-span: 1 !important;
      grid-row: 3/4 !important;
      -ms-grid-column: 1 !important;
      -ms-grid-column-span: 2 !important;
      grid-column: 1/3 !important;
      margin-top: -6rem;
    }

    .meeting-options-container {
      -ms-grid-row: 4 !important;
      -ms-grid-row-span: 1 !important;
      grid-row: 4/5 !important;
      -ms-grid-column: 1 !important;
      -ms-grid-column-span: 5 !important;
      grid-column: 1/6 !important;
      margin-top: -3rem;
      .people-container {
        -ms-grid-column: 1 !important;
        -ms-grid-column-span: 5 !important;
        grid-column: 1/6 !important;
        .people-menu {
          display: -ms-grid;
          display: grid;
          -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          -ms-grid-rows: 1fr;
          grid-template-rows: 1fr;
        }
        .people-menu > *:nth-child(1) {
          -ms-grid-row: 1;
          -ms-grid-column: 1;
        }
        .people-menu > *:nth-child(2) {
          -ms-grid-row: 1;
          -ms-grid-column: 2;
        }
        .people-menu > *:nth-child(3) {
          -ms-grid-row: 1;
          -ms-grid-column: 3;
        }
        .people-menu > *:nth-child(4) {
          -ms-grid-row: 1;
          -ms-grid-column: 4;
        }
        .people-menu > *:nth-child(5) {
          -ms-grid-row: 1;
          -ms-grid-column: 5;
        }
      }
    }
  }
}
