@import '../../UserVar/uservar';

/* SIDEBAR */

.side-bar {
  border-right: solid;
  border-width: thin;
  border-color: $iconUnselect;
  color: $iconUnselect;

  .icon-sidebar {
    display: grid;
    justify-items: center;
    margin-top: 0.5rem;
    font-size: 5rem;
    color: $iconUnselect;
    left: 2rem;

    .calendar {
      background: &topBar;
      border-radius: 0%;
      width: 3.4rem;
      height: 3.4rem;
    }
    .actions-rocket {
      border-radius: 0%;
      width: 3.4rem;
      height: 3.4rem;
      border: none;
    }
    .none {
      margin-top: -0.5rem;
    }

    .sidebar-text {
      margin-top: -0.5rem;
      color: $textSecondary;
    }

    .icon-sidebar:hover {
      color: $topBar;
      p {
        color: $textSecondary;
      }
    }

    p {
      font-size: 1.2rem;
    }

    img {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
    }
  }
  .icon-sidebar:last-child {
    position: fixed;
    bottom: 1rem;
    left: 2rem;
  }
}

@media (max-width: 800px) {
  .side-bar {
    display: none;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .side-bar {
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 1 !important;
    grid-column: 1/2 !important;
    -ms-grid-row: 2 !important;
    -ms-grid-row-span: 1 !important;
    grid-row: 2/3 !important;
    margin-left: 1.8rem;
    .icon {
      .meetings-text {
        margin-left: -0.5rem;
      }
      .attendees-text {
        margin-left: -0.6rem;
      }
    }
  }
}
