.mentions::first-line {
  font-weight: 600 !important ;
}

.mentions--multiLine .mentions__control::first-line {
  font-weight: 600 !important ;
}
.mentions--multiLine .mentions__highlighter {
  color: white;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  color: blue;
  pointer-events: none;
  color: white;
}
