@import 'UserVar/uservar';

/* APP */

.app-container {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: 6.5rem 1fr;
  grid-template-columns: 8rem 1fr 28rem;
  .product-version {
    position: absolute;
    position: fixed;
    color: $textPrimary;
    bottom: -0.5rem;
    right: 1rem;
  }
}

@media only screen and (max-width: 800px) {
  .app-container {
    width: 100vw;
  }
}
